<template>
  <layout-sub name="philosophy">
    <b-card
      v-for="(el, index) in list"
      :key="index"
      no-body
      border-variant="white"
      :class="`rounded-0${index > 0 ? ' mt-3 mt-lg-4 mt-xl-5' : ''}`"
    >
      <div class="p-philosophy-img overflow-hidden">
        <b-card-img :src="el.img" :alt="el.title" class="rounded-0" />
      </div>
    </b-card>
  </layout-sub>
</template>

<script>
import LayoutSub from "@/components/LayoutSub";

export default {
  name: "Philosophy",
  components: { LayoutSub },
  data() {
    return {
      list: [
        {
          title: "原创投资&精品投资",
          img: require("@/assets/img-philosophy-1.jpg"),
        },
        {
          title: "产业链投资&投后赋能",
          img: require("@/assets/img-philosophy-2.jpg"),
        },
        {
          title: "“空间+产业+基金”的创新产业基金模式",
          img: require("@/assets/img-philosophy-3.jpg"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.p-philosophy {
  &-img {
    img {
      width: 100%;
    }
  }
}
</style>
